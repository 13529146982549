<template>
  <div class="bg-img-hero" :style="{ backgroundImage: `url(${require(`@/assets/svg/components/ben-hero-1.svg`)})`}">
    <div class="container space-3">
      <div class="row justify-content-lg-between align-items-lg-center text-center text-lg-left">
        <div class="col-lg-8 mb-5 mb-lg-0">
          <h2>{{ $t('APIProviderPage.CTASection.Title') }}</h2>
          <p>{{ $t('APIProviderPage.CTASection.Description') }}</p>
        </div>

        <div class="col-lg-4 text-center">
          <a class="btn btn-primary btn-wide mx-1 mb-2" href="https://www.fabrixapi.com/" target="_blank">{{ $t('APIProviderPage.CTASection.Button1') }}</a>
          <!-- <router-link
            :to="{ path: `/${$route.params.lang}/contact`}"
            class="btn btn-outline-primary btn-white btn-wide mx-1 mb-2"
          >{{ $t('APIProviderPage.CTASection.Button2') }}
          </router-link> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CTA'
}
</script>

<style lang="scss" scoped>
</style>
