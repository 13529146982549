<template>
  <div id="how-it-works" class="position-relative overflow-hidden">
    <div class="container space-2 space-bottom-lg-3">
      <div class="w-md-85 w-lg-65 text-center mx-auto mb-9">
        <h2>{{ $t('APIProviderPage.FeatureSection.Title') }}</h2>
        <p>{{ $t('APIProviderPage.FeatureSection.Description') }}</p>
      </div>

      <div class="row align-items-center mb-11">
        <div class="col-lg-6 mb-4 mb-lg-0 order-lg-2">
          <div data-gtm-att="ga-intro-video-card" class="card text-indigo text-center w-lg-90 overflow-hidden pt-5 px-5 mx-lg-auto card-hover-shadow" style="background-color:#e7f3fd;">
            <!-- <div class="mb-5">
                <h5>API Portal can be tailored with your own needs</h5>
              </div> -->
            <!-- <div class="device device-half-iphone-x w-85 mx-auto">
              <img class="img-fluid" src="@/assets/svg/illustrations/sample-portal-home.svg">
            </div> -->
            <div class="video-player">
              <img class="img-fluid video-player-preview" src="@/assets/svg/illustrations/sample-portal-home.svg">
              <!-- <div class="video-player-btn video-player-centered">
                <span class="video-player-icon video-player-icon-pill">
                  <i class="fas fa-play-circle mr-2 font-size-2" /> {{ $t('APIProviderPage.FeatureSection.Feature1.WatchButton') }}
                </span>
              </div> -->
              <div class="embed-responsive embed-responsive-4by3">
                <div id="oahVideoIframe" />
              </div>
            </div>
            <div id="videoModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="videoModalTitle" aria-hidden="true">
              <div class="modal-xl modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 id="videoModalTitle" class="modal-title" />
                    <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
                      <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
                      </svg>
                    </button>
                  </div>
                  <div class="modal-body p-2 px-sm-4 pt-0 pb-4">
                    <div style="padding:52% 0 0 0;position:relative;">
                      <iframe :src="$t('AcademyPage.VideoSection.WhatIsOAH.Video')" frameborder="0" allow="autoplay; fullscreen;" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" :title="$t('AcademyPage.VideoSection.WhatIsOAH.Title')" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 order-lg-1">
          <div class="mb-5">
            <h3 class="mb-3">{{ $t('APIProviderPage.FeatureSection.Feature1.Title') }}</h3>
          </div>
          <ul class="step step-dashed mb-4">
            <li class="step-item">
              <div class="step-content-wrapper">
                <span class="step-icon step-icon-xs step-icon-soft-primary"><i class="far fa-check" /></span>
                <div class="step-content">
                  <h4>{{ $t('APIProviderPage.FeatureSection.Feature1.Point1Title') }}</h4>
                  <p>{{ $t('APIProviderPage.FeatureSection.Feature1.Point1Description') }}</p>
                </div>
              </div>
            </li>
            <li class="step-item mb-4">
              <div class="step-content-wrapper">
                <span class="step-icon step-icon-xs step-icon-soft-primary"><i class="far fa-check" /></span>
                <div class="step-content">
                  <h4>{{ $t('APIProviderPage.FeatureSection.Feature1.Point2Title') }}</h4>
                  <p>{{ $t('APIProviderPage.FeatureSection.Feature1.Point2Description') }}</p>
                </div>
              </div>
            </li>
            <li class="step-item mb-0">
              <div class="step-content-wrapper">
                <span class="step-icon step-icon-xs step-icon-soft-primary"><i class="far fa-check" /></span>
                <div class="step-content">
                  <h4>{{ $t('APIProviderPage.FeatureSection.Feature1.Point3Title') }}</h4>
                  <p>{{ $t('APIProviderPage.FeatureSection.Feature1.Point3Description') }}</p>
                </div>
              </div>
            </li>
          </ul>
          <a class="btn btn-primary" href="https://provider-portal.fabrixapi.com/" target="_blank">{{ $t('APIProviderPage.FeatureSection.Feature1.Button') }}</a>
        </div>
      </div>

      <div class="row align-items-center mb-11">
        <div class="col-lg-6 mb-4 mb-lg-0">
          <div class="card shadow-none text-indigo text-center w-lg-90 overflow-hidden pt-5 px-5 mx-lg-auto" style="background-color:#9fcff7;">
            <!-- <div class="mb-5">
                <h5>API Portal can be tailored with your own needs</h5>
              </div> -->
            <div class="device device-half-iphone-x w-85 mx-auto">
              <img class="img-fluid" src="@/assets/img/screenshots/pa-components.png">
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="mb-5">
            <h3 class="mb-3">{{ $t('APIProviderPage.FeatureSection.Feature3.Title') }}</h3>
            <p>{{ $t('APIProviderPage.FeatureSection.Feature3.Description') }}</p>
          </div>
          <ul class="step step-dashed mb-4">
            <li class="step-item">
              <div class="step-content-wrapper">
                <span class="step-icon step-icon-xs step-icon-soft-primary"><i class="far fa-check" /></span>
                <div class="step-content">
                  <p>{{ $t('APIProviderPage.FeatureSection.Feature3.Point1Description') }}</p>
                </div>
              </div>
            </li>
            <li class="step-item mb-0">
              <div class="step-content-wrapper">
                <span class="step-icon step-icon-xs step-icon-soft-primary"><i class="far fa-check" /></span>
                <div class="step-content">
                  <p>{{ $t('APIProviderPage.FeatureSection.Feature3.Point2Description') }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="row align-items-center mb-0">
        <div class="col-lg-6 mb-4 mb-lg-0 order-lg-2">
          <div class="card shadow-none text-indigo text-center w-lg-90 overflow-hidden pt-5 px-5 mx-lg-auto" style="background-color:#c3e1fa;">
            <!-- <div class="mb-5">
                <h5>API Portal can be tailored with your own needs</h5>
              </div> -->
            <div class="device device-half-iphone-x w-85 mx-auto">
              <img class="img-fluid" src="@/assets/img/screenshots/hub-home.jpg">
            </div>
          </div>
        </div>

        <div class="col-lg-6 order-lg-1">
          <div class="mb-5">
            <h3 class="mb-3">{{ $t('APIProviderPage.FeatureSection.Feature2.Title') }}</h3>
            <p>{{ $t('APIProviderPage.FeatureSection.Feature2.Description') }}</p>
            <a class="btn btn-primary" href="https://blog.openapihub.com/en-us/list-api-on-openapihub/" target="_blank">{{ $t('APIProviderPage.FeatureSection.Feature2.CTA') }}</a>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import 'bootstrap'
const $ = require('jquery')

export default {
  name: 'HowItWorks',
  mounted() {
    $('#videoModal').on('hidden.bs.modal', function(e) {
      $('#videoModal iframe').attr('src', $('#videoModal iframe').attr('src'))
    })
  }
}
</script>

<style lang="scss" scoped>
.modal-header {
  border-bottom: 0;
}
</style>
