<template>
  <div>
    <Hero />
    <HowItWorks />
    <!-- <Video /> -->
    <!-- <OahSuite /> -->
    <CTA />
  </div>
</template>

<script>
import Hero from './components/hero'
// import OahSuite from './components/oah-suite'
import HowItWorks from './components/how-it-works'
// import Video from './components/video'
import CTA from './components/cta'

export default {
  name: 'ApiProvider',
  components: {
    Hero,
    // OahSuite,
    HowItWorks,
    // Video,
    CTA
  },
  metaInfo() {
    return {
      title: 'OpenAPIHub',
      titleTemplate: 'OpenAPIHub Suite for API Provider | %s',
      link: [
        { rel: 'canonical', href: 'https://www.openapihub.com/api-provider' }
      ],
      meta: [
        { name: 'description', content: 'OpenAPIHub Suite is the dedicated API platform that gives API providers the flexibility and power they need to expand their API business.' },
        { property: 'og:site_name', content: 'OpenAPIHub' },
        { property: 'og:title', content: 'OpenAPIHub Suite for API Provider | OpenAPIHub' },
        { property: 'og:description', content: 'OpenAPIHub Suite is the dedicated API platform that gives API providers the flexibility and power they need to expand their API business.' },
        { property: 'og:image', content: 'https://www.openapihub.com/assets/img/thumbnails/openapihub-2.jpg' },
        { property: 'og:url', content: 'https://www.openapihub.com/api-provider' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
